<template>
    <div class="errorBox">
        <!-- <slot name="error"></slot> -->
        {{ this.$route.query.message }}
        <!-- 12467890 -->
    </div>
</template>
<script>

</script>
<style>
.errorBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    text-shadow: -3px 4px 6px gray;
}
</style>